import { atom } from "recoil";

export interface PlatformChannels {
    isLoading: boolean;
    available: string[];
    selected: string[];
}

export const CorePlatformChannels = atom<PlatformChannels>({
    key: "core::platform-channels",
    default: {
        isLoading: true,
        available: ["Email", "Sms", "PushNotification"],
        selected: [],
    },
});
