import { DefaultValue, selector } from "recoil";
import { CorePlatformChannels } from "../core/atoms";

export const PlatformChannels = selector({
    key: "public::platform-channels",
    get: ({ get }) => get(CorePlatformChannels),
});

export const PlatformSelectedChannels = selector({
    key: "public::platform-selected-channels",
    get: ({ get }) => get(CorePlatformChannels).selected,
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformChannels, (prev) => ({ ...prev, selected: [] }));
            return;
        }
        set(CorePlatformChannels, (prev) => ({ ...prev, selected: newValue }));
    },
});
